import { useDispatch, useSelector } from "react-redux";
import { changePassWD } from '../actions/user';
import _ from 'lodash';
import { getCurrentAction } from '../reducers/actions';
import NiceModal from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";
import moment from 'moment';

function PhotosPage() {
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0)
  const currentAction = useSelector(getCurrentAction);
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState({
    image1: null,
    image2: null,
  });
  const [img1Capture, setImg1Capture] = useState(false)
  const [img2Capture, setImg2Capture] = useState(false)
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    additionalInfo: '',
    option1: '',
    option2: '',
    option3: '',
  });

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.option1) formErrors.option1 = 'Odpowiedź jest wymagana';
    if (!formData.option2) formErrors.option2 = 'Odpowiedź jest wymagana';
    if (!formData.option3) formErrors.option3 = 'Odpowiedź jest wymagana';
    if (!images.image1) formErrors.image1 = 'Zdjęcie jest wymagane';
    if (!images.image2) formErrors.image2 = 'Zdjęcie jest wymagane';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const sendSecondFile = (e) => {
    let userData = new FormData()
    let input = {
      "action": {
        "uuid": currentAction?.uuid || '1985859a-4cfc-4b8b-98e5-dda5b8f99353'
      },
      "ident": "end",
      "form": {
        "checkboxes": [formData.option1, formData.option2, formData.option3],
        "text": formData.additionalInfo
      }
    }

    userData.append('_input', JSON.stringify(input))
    userData.append('files[]', e.target.image2.files[0])

    fetch(process.env.REACT_APP_API_URL + "/action/file/create", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: userData
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (!result.status?.success) {
            if (result.data.error === "E_FILE_ALREADY_EXISTS") {
              NiceModal.show("webform-error-modal", { error: { description: 'Masz już wgrane zdjęcia w ramach tej akcji', code: result?.meta?.ts ?? "" } });
            } else {
              NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania zdjęć', code: result?.meta?.ts ?? "" } });
            }
          } else {
            NiceModal.show("photos-sent-modal", { error: { description: 'Wystąpił błąd podczas wysyłania zdjęć', code: result?.meta?.ts ?? "" } });
            setFormData({
              additionalInfo: '',
              option1: '',
              option2: '',
              option3: '',
            })
            setImages({
              image1: null,
              image2: null,
            })
            setImg1Capture(false)
            setImg2Capture(false)
          }
          setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    if (!validateForm()) {
      setLoading(false)
      return;
    }

    let userData = new FormData()
    let input = {
      "action": {
        "uuid": currentAction?.uuid || '1985859a-4cfc-4b8b-98e5-dda5b8f99353'
      },
      "ident": "start",
      "form": {
        "checkboxes": [formData.option1, formData.option2, formData.option3],
        "text": formData.additionalInfo
      }
    }

    userData.append('_input', JSON.stringify(input))
    userData.append('files[]', e.target.image1.files[0])

    fetch(process.env.REACT_APP_API_URL + "/action/file/create", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: userData
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (!result.status?.success) {
            if (result.data.error === "E_FILE_ALREADY_EXISTS") {
              NiceModal.show("webform-error-modal", { error: { description: 'Masz już wgrane zdjęcia w ramach tej akcji', code: result?.meta?.ts ?? "" } });
            } else {
              NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania zdjęć', code: result?.meta?.ts ?? "" } });
            }
            setLoading(false)
          } else {
            sendSecondFile(e)
          }
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
  };

  return (
    <section className="photos-page">
      <h1 className="title">Zdjecia produktów</h1>
      {
        currentAction ?
        <div>
          <h2>Aktualnie trwająca akcja:</h2>
          <div className="action-info">
            <h3>{currentAction?.name || '???'}</h3>
            <div>{moment(currentAction?.since?.date).format('YYYY-MM-DD HH:mm')+'-'+moment(currentAction?.until?.date).format('HH:mm')}</div>
          </div>
          <form className="photo-steps" onSubmit={handleSubmit}>
            <div className={stepIndex === 0 ? "step active": "step"}>
              <h3>Rozpocznij od dodania pierwszego zdjęcia:</h3>
              <p>Zdjęcie przed ułożeniem ekspozycji:</p>
              <label className={images.image1 ? "file-input with-image" : "file-input"} onClick={(e) => { e.preventDefault(); NiceModal.show("photos-capture-modal", { setCapture: (val) => { setImg1Capture(val); setTimeout(() => { document.querySelector("input[name=image1]").click() }, 200) }  }) }}>
                <span>Dodaj zdjęcie produktów</span>
                {images.image1 && (
                  <div>
                    <img src={images.image1} alt="Preview 1" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                    <span>Dodaj zdjęcie produktów</span>
                  </div>
                )}
              </label>
              { img1Capture ? <input type="file" name="image1" accept="image/*" capture="environment" onChange={handleImageChange} /> : <input type="file" name="image1" accept="image/*" onChange={handleImageChange} /> }
              {errors.image1 && <p className="error">{errors.image1}</p>}
            </div>
            <div className={stepIndex === 1 ? "step active": "step"}>
              <h3>Dodaj drugie zdjęcie:</h3>
              <p>Zdjęcie po ułożeniu ekspozycji:</p>
              <label className={images.image2 ? "file-input with-image" : "file-input"} onClick={(e) => { e.preventDefault(); NiceModal.show("photos-capture-modal", { setCapture: (val) => { setImg2Capture(val); setTimeout(() => { document.querySelector("input[name=image2]").click() }, 200) }  }) }}>
                <span>Dodaj zdjęcie produktów</span>
                {images.image2 && (
                  <div>
                    <img src={images.image2} alt="Preview 2" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                  </div>
                )}
              </label>
              { img2Capture ? <input type="file" name="image2" accept="image/*" capture="environment" onChange={handleImageChange} /> : <input type="file" name="image2" accept="image/*" onChange={handleImageChange} /> }
              {errors.image2 && <p className="error">{errors.image2}</p>}
            </div>
            <div className={stepIndex === 2 ? "step active": "step"}>
              <h3>Wypełnij ankietę:</h3>
              <div className="input-row">
                1. Czy nasze produkty są wyeksponowane na meblu?
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option1"
                      value="tak"
                      checked={formData.option1 === 'tak'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Tak
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option1"
                      value="nie"
                      checked={formData.option1 === 'nie'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Nie
                  </label>
                </div>
                {errors.option1 && <p className="error">{errors.option1}</p>}
              </div>
              <div className="input-row">
                2. Czy planogram został przygotowany?
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option2"
                      value="tak"
                      checked={formData.option2 === 'tak'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Tak
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option2"
                      value="nie"
                      checked={formData.option2 === 'nie'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Nie
                  </label>
                </div>
                {errors.option2 && <p className="error">{errors.option2}</p>}
              </div>
              <div className="input-row">
                3. Czy przeprowadzono szkolenie dla detalisty?
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option3"
                      value="tak"
                      checked={formData.option3 === 'tak'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Tak
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="option3"
                      value="nie"
                      checked={formData.option3 === 'nie'}
                      onChange={handleInputChange}
                    />
                    <span className="radio-custom"></span>
                    Nie
                  </label>
                </div>
                {errors.option3 && <p className="error">{errors.option3}</p>}
              </div>
              <div className="input-row">
                <label>
                  4. Twój komentarz (opcjonalnie)
                  <textarea
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
              <button type="submit" disabled={loading ? true : false} className={`btn btn-dark`}>{loading ? "Proszę czekać..." : "Zapisz"}</button>
            </div>
          </form>
        </div>
        : 
        <div className="no-actions">
          <h2>Brak aktywnych akcji</h2>
          <p>Pozpocznij nową akcję, aby móc dodać zdjęcia produktów</p>
        </div>
      }
    </section>
  );
}

export default PhotosPage;