import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { numberPrefixes } from './data';
import InputMask from 'react-input-mask';
import { assetSources, neoOffers, veloSampling } from './data'

export const sendEvent = (event, action, label, value = null) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      'event': event,
      'eventCategory': "Form",
      'eventAction': action,
      'eventLabel': label,
      'eventValue': value
    });
  }
}

export function Input({ register, name, errors, disabled, shouldUnregister, label, placeholder, onInputBlur, hidden = false  }) {
  return (
    <div className={`input-wrapper ${disabled ? "disabled" : ""} ${errors?.[name]?.message ? "error" : ""}  ${hidden ? "hidden" : ""}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="input">
        <input
          {...register(name, {
            shouldUnregister: shouldUnregister,
            onBlur: e => { if (onInputBlur) { onInputBlur(e) } }
          })}
          name={name}
          type={name === "emailAddress" ? "email" : name === "mobileNumber" ? "tel" : "text"}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {errors[name] && <div className="error">
        {errors[name].message}
      </div>}
    </div>
  )
}

export function Select({ control, options, name, errors, shouldUnregister, disabled, label, placeholder, onInputBlur, onInputChange }) {
  let defaultValue = false

  if (options?.length === 1) {
    defaultValue = options[0].value
  }

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      {...(defaultValue && { defaultValue: defaultValue })}
      render={({ field: { onChange, value, name, ref, onBlur } }) => {
        const currentSelection = options.find(
          (e) => e.value === value
        );
        const handleSelectChange = (selectedOption) => {
          onChange(selectedOption?.value);
          if (onInputChange) {
            onInputChange()
          }
        };

        return (
          <div className={`input-wrapper dropdown ${disabled ? "disabled" : ""} ${errors?.[name]?.message ? "error" : ""}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <ReactSelect
              inputRef={ref}
              onBlur={e => { onBlur(e); if (onInputBlur) { onInputBlur(e) } }}
              value={options.length === 1 ? options[0] : currentSelection ?? ""}
              name={name}
              options={options}
              onChange={handleSelectChange}
              isSearchable={false}
              isDisabled={disabled || options.length < 2}
              id={name}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={placeholder}
            />
            {errors[name] && <div className="error">
              {errors[name].message}
            </div>}
          </div>
        );
      }}
    />
  )
}

export function PhoneNumber({ register, control, errors, disabled }) {
  return (
    <div className="phone-number-wrapper">
      <Select
        options={numberPrefixes}
        name={"numberPrefix"}
        label={"Numer telefonu"}
        control={control}
        errors={errors}
        disabled={disabled}
      />

      <Input
        name={"mobileNumber"}
        placeholder={"np. 501502503 "}
        label={""}
        register={register}
        errors={errors}
        disabled={disabled}
        onInputBlur={() => { sendEvent("input", "input", "TELEFON") }}
      />
    </div>
  )
}


// const PolishPostalCodeInput = () => {
//   const handleInput = (event) => {
//     event.target.value = event.target.value.replace(/^(\d{2})(\d)/, '$1-$2');
//   };

//   return (
//     <input
//       type="text"
//       pattern="[0-9]{2}-[0-9]{3}"
//       placeholder="XX-XXX"
//       required
//       onInput={handleInput}
//     />
//   );
// };


export function BirtdayPostalCode({ register, name, errors, disabled, shouldUnregister, label, placeholder }) {
  return (
    <div className="birthday-postal-code">
      <div className={`input-wrapper ${disabled ? "disabled" : ""} ${errors?.["birthDate"]?.message ? "error" : ""}`}>
        <label htmlFor={"birthDate"}>Data urodzenia</label>
        <div className="input">
          <input
            {...register("birthDate")}
            type="date"
            id='birthDate'
            placeholder='mm/dd/yyyy'
            max={new Date().toISOString().split("T")[0]}
            min={"1900-01-01"}
          />
        </div>
        {errors["birthDate"] && <div className="error">
          {errors["birthDate"].message}
        </div>}
      </div>

      <div className={`input-wrapper ${disabled ? "disabled" : ""} ${errors?.["billingPostalCode"]?.message ? "error" : ""}`}>
        <label htmlFor={"billingPostalCode"}>Kod pocztowy</label>
        <div className="input">
          <InputMask {...register("billingPostalCode")} name="billingPostalCode" mask="99-999" placeholder="xx-xxx"></InputMask>
        </div>
        {errors["billingPostalCode"] && <div className="error">
          {errors["billingPostalCode"].message}
        </div>}
      </div>
    </div>
  )
}

export function Checkbox({ register, name, errors, disabled, shouldUnregister, label, onInputBlur}) {
  return (
    <div className={`checkbox-wrapper ${errors?.[name]?.message ? "error" : ""}`}>
      <label htmlFor={name}>
        <input
          {...register(name, {
            shouldUnregister: shouldUnregister,
            onBlur: e => { if (onInputBlur) { onInputBlur(e) } }
          })}
          type="checkbox"
          id={name}
          name={name}
          disabled={disabled}
        />
        <p dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      {errors[name] && <div className="error">
        {errors[name].message}
      </div>}
    </div>
  );
}

export function Submit({ isLoading = false, isValid, children, label }) {
  return (
    <div className="button-wrapper">
      <>
        {children}
        <button className='btn' disabled={isLoading === isValid}>
          {isLoading ? "Wysyłanie" : label}
        </button>
      </>
    </div>
  )
}

export function Form({ defaultValues, children, onSubmit, schema }) {
  const [schemaErrors, setSchemaErrors] = useState({})
  const methods = useForm({
    mode: "onBlur", resolver: async (data, context, options) => {
      const { errors } = await zodResolver(schema)(data, context, options)
      setSchemaErrors(errors)
      return zodResolver(schema)(data, context, options)
    }, defaultValues
  });
  const { handleSubmit } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post" autoComplete="off">
      {React.Children.map(children, child => {
        return typeof (child?.type) === "function"
          ? React.createElement(child.type, {
            ...{
              ...child.props,
              register: methods.register,
              key: child.props.name,
              disabled: child.props?.disabled ?? false,
              errors: methods.formState.errors,
              isValid: methods.formState.isValid,
              shouldUnregister: child.props?.shouldUnregister ?? false,
              control: methods.control,
              onInputBlur: child.props?.onInputBlur ?? false,
              label: child.props?.label ?? "",
              placeholder: child.props?.placeholder ?? "",
              watch: methods.watch,
              trigger: methods.trigger,
              setValue: methods.setValue,
              schemaErrors: schemaErrors,

              getValues: methods.getValues
            }
          })
          : child;
      })}
    </form>
  );
}