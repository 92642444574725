import { create } from 'zustand'
import { API_URL, appFetch, fetchData } from '../../helpers/fetch'
import NiceModal, { register } from '@ebay/nice-modal-react'
import { assetSources, neoOffers, registerType, veloSampling } from './data'

const parseObject = object => {
  let prasedObject = { ...object }
  for (const property in prasedObject) {
    if (typeof prasedObject[property] === "object") {
      prasedObject[property] = Object.values(prasedObject[property]).every(e => e === true)
    }
  }
  return prasedObject
}

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

export const useWebformStore = create((set, get) => ({
  webformData: {
  },
  webformState: {
    step: 0,
    verified: false,
    data: "",
    newUser: false,
    areOffersChosen: false,
    isSuccess: false,
    icConsentStatus: false
    // string - error
    // false  - hidden
    // true   - success
  },
  token: JSON.parse(window.localStorage.getItem("state"))?.user?.token?.uuid,
  isLoading: false,
  currentAction: {},
  userID: "",
  recieptURL: "",

  addDataToStore: (formData) => set((state) => ({ webformData: { ...state.webformData, ...formData } })),

  setVerified: (value) => set(state => ({ webformState: { ...state.webformState, verified: value } })),

  setOfferChosen: (value) => set(state => ({ webformState: { ...state.webformState, areOffersChosen: value } })),

  nextStep: () => set((state) => ({ webformState: { ...state.webformState, step: state.webformState.step + 1 } })),

  prevStep: () => set((state) => ({ webformState: { ...state.webformState, step: state.webformState.step - 1 } })),

  setCurrentAction: (data) => set({ currentAction: data }),

  setUserID: (data) => set({ userID: data }),

  resetForm: () => set(() => ({ webformData: {}, webformState: { step: 0, verified: false, data: "", newUser: false, areOffersChosen: false, isSuccess: false, icConsentStatus: false }, isLoading: false, recieptURL: "" })),

  setRecieptURL: (url) => set(() => ({ recieptURL: url })),

  sendOtp: (toNextStep = true) => {
    fetchData({
      endpoint: "/lead/otp",
      token: get().token,
      payload: {
        lead: {
          process: "TOURPLANNER",
          emailAddress: get().webformData.emailAddress,
          mobileNumber: get().webformData.numberPrefix + get().webformData.mobileNumber,
          consent: {
            inspirationClub: get().webformData.inspirationClubConsent,
          }
        }
      },
      setLoading: (value) => set({ isLoading: value }),
      successCallback: () => {
        if (toNextStep) {
          get().nextStep()
        }
      },
      errorCallback: resObj => {
        NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd', code: resObj.meta.ts } });
      },
      fetchErrorCallback: () => {
        NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania żądania. Sprawdź połączenie z internetem' } });
      }
    })
  },

  getAvailableList: (verified) => {
    fetchData({
      endpoint: "/lead/verify",
      token: get().token,
      setLoading: (value) => set({ isLoading: value }),
      payload: {
        lead: {
          process: "TOURPLANNER",
          emailAddress: get().webformData.emailAddress,
          otpEmail: get().webformData.otpEmail,
          mobileNumber: get().webformData.numberPrefix + get().webformData.mobileNumber,
          mobileNumberNoPrefix: get().webformData.mobileNumber,
          otp: get().webformData.otp,
          eventIdent: get().currentAction.event.ident,
          phoneVerified: verified,
          consent: {
            inspirationClub: get().webformData.inspirationClubConsent,
          }
        }
      },
      successCallback: resObj => {
        set(() => ({ webformState: { verified: true, step: 2, data: parseObject(resObj.data) } }))
        if(get().webformState?.data?.Glo_Pro)
          NiceModal.show("webform-available-modal")
      },
      errorCallback: resObj => {
        if (resObj.data.error === "error_m2_not_found_in_salesforce") {
          set(() => ({
            webformState: {
              verified: true, step: 2, data: {
                Glo: true,
                Glo_HUTD: true,
                Vuse: true,
                Vype: true,
                Neo: true,
                Velo: true,
                Epok: true,
                InspirationClub: get().webformData.inspirationClubConsent,
              },
              newUser: true
            }
          }))
          return
        }
        set(() => ({ webformState: { verified: false, step: 1, data: resObj.data.error } }))
        NiceModal.show("webform-otp-error-modal", { error: resObj.data.error });
      },
      fetchErrorCallback: () => {
        NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania żądania. Sprawdź połączenie z internetem' } });
      }
    })
  },

  burn: () => {
    if (get().webformData?.selectedOffers?.Glo?.serialNumber) {
      const data = {
        "device": {
          "serialNumber": get().webformData?.selectedOffers?.Glo?.serialNumber
        }
      }

      appFetch("POST", API_URL, '/device/burn', data, get().token ? get().token : false, (result) => { })
    }
  },

  createReciept: bc => {
    if (get().recieptURL.length === 0) {
      return
    }
    let userData = new FormData()
    let input = {
      "action": {
        "uuid": get().currentAction.uuid
      },
      "device": {
        serial: get().webformData.deviceName || "fake_serial",
        beacon: bc || "fake_beacon"
      }
    }

    userData.append('_input', JSON.stringify(input))
    userData.append('files[]', DataURIToBlob(get().recieptURL))

    fetch(process.env.REACT_APP_API_URL + "/action/receipt/create", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: userData
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (!result.status?.success) {
            NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania paragonu', code: result?.meta?.ts ?? "" } });
          }
        },
        (error) => {
          console.log(error)
        }
      )
  },

  sendForm: (verified) => {
    const values = get().webformData
    // if something isn't in schema it wont be in webformData at given step

    const { selectedOffers } = values

    const selectedOffersDevices = Object.keys(selectedOffers).find(e => e.includes("Glo") || e === "Vuse")


    let data = {
      process: 'TOURPLANNER',
      otp: values.otp,
      otpEmail: values.otpEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      mobileNumber: values.numberPrefix + values.mobileNumber,
      mobileNumberNoPrefix: values.mobileNumber,
      tourPlan: values.tourPlan,
      hostessCode: values.hostessCode,
      accountSource: 'Hostess',
      assetSource: "", //array? second device
      emailVerificationStatus: "Verified",
      emailVerified: true,
      phoneVerified: verified,
      ageVerified: true,
      pointIdent: values?.registerLocation,
      formIdent: 'glo.pl-tourplanner',
      lastModifiedSource: 'Hostess',
      category: ['user-register', 'neo-offer'].includes(values.registerType) ? "Other" : values.category, // samo category
      secondaryBrand: values?.secondaryBrand ?? "",
      deviceName: values?.deviceName ?? "",
      eventIdent: get().currentAction.event.ident,
      birthDate: Boolean(values.birthDate) ? new Date(values.birthDate).toISOString().split('T')[0] : "",
      billingPostalCode: values.billingPostalCode,
      consent: {
        ageVerified: values.ageVerified,
        externalCompaniesAuthorization: values.externalCompaniesAuthorization,
        mobileOptIn: values.mobileOptIn,
        socialMediaOptIn: values.socialMediaOptIn,
        newsletterCommercialAuthorization: values.newsletterCommercialAuthorization,
        hypercareOptIn: values.hypercareOptIn ?? false,
        personalDataRegistrationAuthorisation: true,
        personalDataRegistrationHandling: true,
        inspirationClub: values.selectedOffers?.InspirationClub && values.inspirationClubConsent
      }
    }

    function assetItemsCreator() {
      const { selectedOffers } = values

      const selectedOffersDevices = Object.keys(selectedOffers)

      const firstDeviceBrand = selectedOffersDevices[0]
      const firstDevice = selectedOffers[firstDeviceBrand]

      if(!selectedOffersDevices.some(e => e.includes("Glo") || e === "Vuse")) {
        return []
      }

      if (selectedOffersDevices.some(e => e.includes("Glo")) && selectedOffersDevices.includes("Vuse")) {
        const secondDeviceBrand = selectedOffersDevices[1]
        const secondDevice = selectedOffers[secondDeviceBrand]

        return [{
          brand: firstDeviceBrand.replace("_HUTD", ""),
          colour: firstDevice.model === "Vuse Go" ? "unspecified" : firstDevice.color,
          ...(firstDevice.model === "Vuse Go" && { taste: firstDevice.color }),
          status: 'Active',
          purchaseDate: new Date().toISOString().split('T')[0],
          model: firstDevice.model,
          type: 'Sold',
          // may be useless
          storeId: values?.registerLocation,
          tourPlan: values.tourPlan,
          assetSource: assetSources.get(firstDeviceBrand).find(s => s.label === firstDevice.assetSource)?.value,
          // ...(secondDeviceBrand === "Vuse" && { deviceName: secondDevice.model === "Vuse Go" ? `bundle_PLH_${secondDevice.color}` : secondDevice.deviceName }),
          ...(firstDeviceBrand === "Vuse" && { deviceName: "Vuse Go" }),
          ...(firstDeviceBrand.includes("Glo") && { serialNumber: firstDevice.serialNumber }),
        },
        {
          brand: secondDeviceBrand.replace("_HUTD", ""),
          colour: secondDevice.model === "Vuse Go" ? "unspecified" : secondDevice.color,
          ...(secondDevice.model === "Vuse Go" && { taste: secondDevice.color }),
          status: 'Active',
          purchaseDate: new Date().toISOString().split('T')[0],
          model: secondDevice.model,
          type: 'Sold',
          // may be useless
          storeId: values?.registerLocation,
          tourPlan: values.tourPlan,
          assetSource: assetSources.get(secondDevice).find(s => s.label === secondDevice.assetSource)?.value,
          // ...(secondDeviceBrand === "Vuse" && { deviceName: secondDevice.model === "Vuse Go" ? `bundle_PLH_${secondDevice.color}` : secondDevice.deviceName }),
          ...(secondDeviceBrand === "Vuse" && { deviceName: "Vuse Go" }),
          ...(secondDeviceBrand.includes("Glo") && { serialNumber: secondDevice.serialNumber }),
        }
        ]
      }

      return [{
        brand: firstDeviceBrand.replace("_HUTD", ""),
        colour: firstDevice.model === "Vuse Go" ? "unspecified" : firstDevice.color,
        ...(firstDevice.model === "Vuse Go" && { taste: firstDevice.color }),
        status: 'Active',
        purchaseDate: new Date().toISOString().split('T')[0],
        model: firstDevice.model,
        type: 'Sold',
        // may be useless
        storeId: values?.registerLocation,
        tourPlan: values.tourPlan,
        assetSource: assetSources.get(firstDeviceBrand).find(s => s.label === firstDevice.assetSource)?.value,
        ...(firstDeviceBrand === "Vuse" && { deviceName: "Vuse Go" }),
        ...(firstDeviceBrand.includes("Glo") && { serialNumber: firstDevice.serialNumber }),
      },]
    }

    function productInterest() {
      const { selectedOffers } = values

      let finalArray = []

      for (const [brand, values] of Object.entries(selectedOffers)) {

        switch (brand) {
          case 'Velo':
            finalArray.push({
              "sku": veloSampling.find(s => s.label === values.sampling)?.sku,
              "status": "Trial",
              "unitOfMeasure": "Can",
              "name": !Object.keys(selectedOffers).some(e => e === "Glo" || e === "Vuse") ? values.sampling + "_solo" : values.sampling,
              tourPlan: values.tourPlan
            })
            break;
          case 'Glo':
            finalArray.push({
              "sku": assetSources.get('Glo').find(s => s.label === values.assetSource)?.sku,
              "status": "Trial",
              "unitOfMeasure": "Can",
              "name": assetSources.get('Glo').find(s => s.label === values.assetSource)?.label,
              tourPlan: values.tourPlan
            })
            break
          case 'Glo_HUTD':
            finalArray.push({
              "sku": assetSources.get('Glo_HUTD').find(s => s.label === values.assetSource)?.sku,
              "status": "Trial",
              "unitOfMeasure": "Can",
              "name": assetSources.get('Glo_HUTD').find(s => s.label === values.assetSource)?.label,
              tourPlan: values.tourPlan
            })
            break
          case 'Neo':
            finalArray.push({
              sku: neoOffers.find(e => e.value === values.neoOffers)?.sku,
              "status": "Trial",
              "unitOfMeasure": "Can",
              name: neoOffers.find(e => e.value === values.neoOffers)?.label,
              tourPlan: values.tourPlan
            })
            break
          default:
            break
        }
      }

      return finalArray.length === 0 ? null : finalArray
    }

    data = {
      lead: {
        ...data,
        brand: Object.keys(values.selectedOffers)[0] === 'Neo' ? "Glo" : Object.keys(values.selectedOffers)[0],
        ...(assetItemsCreator() && { assetItems: assetItemsCreator() }),
        ...(productInterest() && { productInterestItems: productInterest() })
      }
    }

    fetchData({
      endpoint: "/lead/register",
      payload: data,
      token: get().token,
      setLoading: (value) => set({ isLoading: value }),
      successCallback: resObj => {
        get().createReciept(resObj.meta?.bc)
        get().burn()
        set(state => ({ webformState: { ...state.webformState, step: 4, isSuccess: true, icConsentStatus: data.lead.consent.inspirationClub } }))
      },
      errorCallback: resObj => {
        if (resObj.data.error === "error_ic_registration_failed") {
          get().createReciept(resObj.meta?.bc)
          get().burn()
          set(state => ({ webformState: { ...state.webformState, step: 4, isSuccess: true, icConsentStatus: resObj.meta.ts } }))
        } else if (resObj.data.error === "error_device_not_found") {
          NiceModal.show("webform-error-modal", { error: { description: 'Podany my glo™ number nie jest poprawny', code: resObj.meta.ts } });
        } else if (resObj.data.error === "error_device_already_registered") {
          NiceModal.show("webform-error-modal", { error: { description: 'Ten my glo™ number został już zarejestrowany', code: resObj.meta.ts } });
        } else if (resObj.data.error === "error_asset_unavailable") {
          NiceModal.show("webform-error-modal", { error: { description: 'Rejestracja nie jest możliwa ze względu na zmiany na koncie konsumenta dokonane po weryfikacji mail/sms. W celu rejestracji konsumenta należy powtórzyć proces weryfikacji', code: resObj.meta.ts } });
        } else if (resObj?.targetErrorTrace?.targetErrorCode === "ASSET:CREATION_ERROR") {
          NiceModal.show("webform-error-modal", { error: { description: 'To urządzenie jest już zarejestrowane. Sprawdź proszę ponownie numer urządzenia.', code: "" } });
        } else {
          NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd', code: resObj.meta.ts } });
        }
      },
      fetchErrorCallback: () => {
        NiceModal.show("webform-error-modal", { error: { description: 'Wystąpił błąd podczas wysyłania żądania. Sprawdź połączenie z internetem' } });
      }
    })

  }
}))